import React from 'react'

import Layout from '../components/Layout';
import { Heading1, Paragraph } from '../components/text';


function NotFound() {
  return (
    <Layout title="Page Not Found" className="py-5 text-center">
      <Heading1 className="text-secondary">Page Not Found</Heading1>
      <Paragraph sm={1} md={1.5}>
        You just hit a route that doesn&#39;t exist... the sadness.
      </Paragraph>
    </Layout>
  );
}

export default NotFound;

